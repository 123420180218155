import {setReducerLite} from "../utils/reducers";

/**
 *  Get job levels reducer.
 *
 * @param state{object} Redux state.
 * @param action{object} Action object.
 *
 * @returns {{items}|{}|{items: *[]}}
 */
const jobLevels = (state = {}, action) => setReducerLite(state, action, "JOB_LEVELS");

export default jobLevels;
