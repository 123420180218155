import logoutReq from "../actions/logout";

/**
 * Determine is Dev mode.
 *
 * @returns {boolean} True if is dev mode.
 */
export const isDev = () => {
    return process.env.NODE_ENV === 'development'
};

/**
 * Standard error handler with message.
 *
 * @param err Error object.
 * @param message Error message.
 * @param dispatch Dispatcher. Optional.
 * @param dispatchType Dispatch type. Optional.
 *
 * @returns {Promise<never>} Promise rejected.
 */
export const sendReject = (err: ?Object, message: ? string, dispatch?: any, dispatchType = '') => {
    if (dispatchType && dispatch) {
        dispatch({
            type: dispatchType,
            payload: err && err.message ? err && err.message : message,
        });

        if (err?.response?.status === 401) {
            dispatch(logoutReq());
        }
    } else {
        return Promise.reject(err && err.response && err.response.data ? err.response.data.data : message)
    }
}

/**
 * Standard success handler.
 *
 * @param response Response object.
 * @param dispatch Dispatcher.
 * @param dispatchType Dispatch type.
 *
 * @returns {Promise<void>}
 */
export const sendResolve = (response: Object, dispatch: any, dispatchType: string) => {
    if (response.status === 200 && response.data) {
        dispatch({
            type: dispatchType,
            payload: response.data.data,
        });
        return Promise.resolve();
    }
    return Promise.reject("Cannot send properly response.")
}

/**
 * Set item to localStorage.
 *
 * @param key{string} Local storage field key.
 * @param value{any} Value for key.
 */
export const setLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
}

/**
 * Get item from local storage.
 *
 * @param key{string} Key name.
 *
 * @returns {object|null} Null or json object.
 */
export const getLocalStorage = (key) => {
    return JSON.parse(localStorage.getItem(key)) ?? null;
}

/**
 * Clear localStorage or unset item.
 *
 * @param key{string} Local storage field key.
 */
export const clearLocalStorage = (key ?: string) => {
    if (key) {
        localStorage.removeItem(key)
    } else {
        localStorage.clear()
    }
}

/**
 * Parse data to Dropdown markup
 * @param data
 * @param key
 * @returns {*}
 */
export const parseDataToDropdown = (data, key) => data &&
    data.map((item) => {
        return {label: item[key], value: item.id};
    })

/**
 *
 * @param searchForContacts
 * @returns {*&{NAICSId: (unknown[]|*[]), IndustryId: (unknown[]|*[]), JobFunctionId: (unknown[]|*[]), JobLevelId: (unknown[]|*[]), AnnualRevenueId: (unknown[]|*[]), EmployeeSizeId: (unknown[]|*[])}}
 */
export const parseSearchDataForFetch = (searchForContacts) => {
    return {
        ...searchForContacts,
        NAICSId: Array.isArray(searchForContacts.NAICSId) ? searchForContacts.NAICSId.map(value => value?.value || value) : [],
        JobLevelId: Array.isArray(searchForContacts.JobLevelId) ? searchForContacts.JobLevelId.map(value => value?.value || value) : [],
        JobFunctionId: Array.isArray(searchForContacts.JobFunctionId) ? searchForContacts.JobFunctionId.map(value => value?.value || value) : [],
        IndustryId: Array.isArray(searchForContacts.IndustryId) ? searchForContacts.IndustryId.map(value => value?.value || value) : [],
        EmployeeSizeId: Array.isArray(searchForContacts.EmployeeSizeId) ? searchForContacts.EmployeeSizeId.map(value => value?.value || value) : [],
        AnnualRevenueId: Array.isArray(searchForContacts.AnnualRevenueId) ? searchForContacts.AnnualRevenueId.map(value => value?.value || value) : [],
    }
}

/**
 *
 * @param fields
 * @param items
 * @param label
 * @returns {*[]|*}
 */
export const parseSearchValueForDropdown = (fields, items, label) => {
    if (!fields) {
        return [];
    }

    if (typeof fields === 'string' || typeof fields === 'number') {
        fields = [fields];
    }

    return fields.map(value => {
        const item = items.find(el => el.id === value || el.id === value.value);
        if (item) {
            return {
                value: value.value || value,
                label: item[label]
            }
        }
    });
}