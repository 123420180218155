import {getLocalStorage} from "../utils/helpers";
import {setReducer} from "../utils/reducers";

/**
 * Reducer initial state values.
 *
 * @type {{item: (Object|null)}}
 */
const initialState = {item: getLocalStorage("user")};

/**
 * Current user status changer reducer.
 *
 * @param state{object} Redux state.
 * @param action{object} Action object.
 *
 * @returns {{item: (Object|null)}|{item: null}|{item}}
 */
const currentUser = (state = initialState, action) => setReducer(state, action, "USER");

export default currentUser;
