import React, {lazy, Suspense, useEffect} from "react";
import {useSelector} from "react-redux";
import {Redirect, Route, Switch} from "react-router-dom";

import Loader from "react-loader-spinner";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {getBearer} from "../app/utils/cookies";
import logoutReq from "../app/actions/logout";

const Login = lazy(() => import("./login"));
const Dashboard = lazy(() => import("sections"));
const NotFound = lazy(() => import("./notFound"));
const ResetPassword = lazy(() => import("./resetPassword"));
const ForgotPassword = lazy(() => import("./forgotPassword"));

const Pages = () => {
    // noinspection JSUnresolvedVariable
    const {isLoggedIn} = useSelector((state) => state.login);

    useEffect(() => {
        if (!getBearer()) {
            logoutReq()
        }
    }, [])

    return (
        <Suspense
            fallback={
                <Loader
                    type="BallTriangle"
                    color="#012488"
                    height={75}
                    width={75}
                    timeout={-1}
                    style={{
                        position: "fixed",
                        left: "calc(50% - 37.5px)",
                        top: "calc(50% - 37.5px)",
                    }}
                />
            }
        >
            <Switch>
                <Route exact path="/">
                    {isLoggedIn ? <Redirect to="/current-campaigns"/> : <Redirect to="/login"/>}
                </Route>
                <Route exact path="/login">
                    {isLoggedIn ? <Redirect to="/current-campaigns"/> : <Login/>}
                </Route>
                <Route exact path="/reset-password">
                    {isLoggedIn ? <Redirect to="/current-campaigns"/> : <ResetPassword/>}
                </Route>
                <Route exact path="/forgot-password">
                    {isLoggedIn ? <Redirect to="/current-campaigns"/> : <ForgotPassword/>}
                </Route>
                <Route
                    exact
                    path={[
                        "/reports",
                        "/notifications",
                        "/search-history",
                        "/search-results",
                        "/campaign-history",
                        "/current-campaigns",
                    ]}
                >
                    {isLoggedIn ? <Dashboard/> : <Redirect to="/login"/>}
                </Route>
                {process.env.NODE_ENV === "development" && (
                    <Route exact path="/ui">
                        {isLoggedIn ? <Dashboard/> : <Redirect to="/login"/>}
                    </Route>
                )}
                <Route>
                    <NotFound/>
                </Route>
            </Switch>
        </Suspense>
    );
};

export default Pages;
