import {setReducerLite} from "../utils/reducers";

/**
 * Get countries reducer.
 *
 * @param state{object} Redux state.
 * @param action{object} Action object.
 *
 * @returns {{items}|{}|{items: *[]}}
 */
const countries = (state = {}, action) => setReducerLite(state, action, "COUNTRIES");

export default countries;
