import {setReducerLite} from "../utils/reducers";

/**
 * Get revenues reducer.
 *
 * @param state{object} Redux state.
 * @param action{object} Action object.
 *
 * @returns {{items}|{}|{items: *[]}}
 */
const revenues = (state = {}, action) => setReducerLite(state, action, "REVENUES");

export default revenues;
