import {setReducer} from "../utils/reducers";

/**
 * Get campaign by ID reducer.
 *
 * @param state{object} Redux state.
 * @param action{object} Action object.
 *
 * @returns {{data, loading: boolean, error: (null|*|string)}}
 */
const getCampaignById = (state, action) => setReducer(state, action, "GET_CAMPAIGN_BY_ID");

export default getCampaignById;
