/**
 * Reducer initial state values.
 *
 * @type {{data: null, loading: null, error: null}}
 */
const initialState = {
    data: null,
    loading: null,
    error: null,
};

/**
 * Main Reducer helper for updating state depends on action type.
 * Function check for 3 main actions by action name.
 * actionName_START, actionName_SUCCESS and actionName_FAIL.
 *
 * @param state{object} Redux state.
 * @param action{object} Action object.
 * @param actionName{string} Action Name.
 *
 * @returns {{data, loading: boolean, error: (null|*|string)}}
 */
export const setReducer = (state = initialState, action, actionName) => {
    switch (action.type) {
        case `${actionName}_START`:
            return {
                ...state,
                loading: true,
            };
        case `${actionName}_SUCCESS`:
            return {
                ...state,
                data: action.payload.data && Array.isArray(action.payload.data) ? action.payload.data : action.payload,
                loading: false,
            };
        case `${actionName}_FAIL`:
            return {
                ...state,
                data: null,
                loading: false,
                error: action?.payload || `Something went wrong on ${actionName}`.toUpperCase(),
            };
        default:
            return state;
    }
}

/**
 * Secondary Reducer helper for updating state depends on action type.
 * Function check for 2 actions by action name.
 * GET_actionName_START and CLEAN_actionName.
 *
 * @param state{Object}
 * @param action{Object}
 * @param actionName{string}
 *
 * @param defaultType
 * @returns {Object}
 */
export const setReducerLite = (state, action, actionName, defaultType = []) => {
    switch (action.type) {
        case `GET_${actionName}_SUCCESS`:
            return {
                ...state,
                items: action.payload,
            };
        case `CLEAN_${actionName}`:
            return {
                ...state,
                items: defaultType,
            };
        default:
            return state;
    }
}