/**
 * Endpoints mapping
 * @type {{userActivePackage: string, conversationModel: string, campaignByID: string, campaignRecords: string, loginForgot: string, personalData: string, addCampaign: string, callStatistics: string, campaignPaused: string, campaignCompleted: string, countries: string, campaignStarted: string, login: string, revenues: string, loginReset: string, employeeSize: string, industries: string, searchContact: string, jobFunctions: string, userCampaign: string, naics: string, jobLevels: string}}
 */
const endpointMapping = {
    'login': 'account/login',
    'loginReset': 'account/ResetPassword',
    'loginForgot': 'account/ForgotPassword',
    'personalData': 'account/GetPersonalData',
    'naics': 'Contact/GetNAICSes',
    'revenues': 'Contact/GetRevenues',
    'jobLevels': 'Contact/GetJobLevels',
    'industries': 'Contact/GetIndustries',
    'countries': 'Contact/GetCountryRegions',
    'jobFunctions': 'Contact/GetJobFunctions',
    'employeeSize': 'Contact/GetEmployeeSizes',
    'searchContact': 'Contact/GetContacts',
    'addCampaign': 'Campaign/AddCampaign',
    'userActivePackage': 'UserPackage/GetActiveUserPackage',
    'userCampaign': 'Campaign/GetUserCampaign',
    'campaignRecords': 'Campaign/GetCampaignRecordings',
    'callStatistics': 'Statistics/GetCampaignEmailStatistics',
    'campaignByID': 'Campaign/GetCampaignByCampaignId',
    'conversationModel': 'ConversationalModel/GetConversationalModels',
    'campaignPaused': 'Campaign/SetCampaignStatusPaused',
    'campaignCompleted': 'Campaign/SetCampaignStatusCompleted',
    'campaignStarted': 'Campaign/SetCampaignStatusStarted',
};

export default endpointMapping;