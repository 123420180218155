import React from "react";

import Pages from "pages";

/**
 * Main Layout wrapper .
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Layout = () => {
    return <Pages/>;
};

export default Layout;