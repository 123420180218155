import {setReducer} from "../../utils/reducers";

/**
 * Set campaign status started reducer.
 *
 * @param state{object} Redux state.
 * @param action{object} Action object.
 *
 * @returns {{data, err: (*|string), loading: boolean, error: null}|{data: null, loading: boolean, error: null}|{data: null, loading: boolean, error: null}|{data, loading: boolean, error: null}}
 */
const setCampaignStatusStarted = (state, action) => setReducer(state, action, "SET_STATUS_START");

export default setCampaignStatusStarted;
