import {setReducer} from "../utils/reducers";

/**
 * Get campaigns reducer.
 *
 * @param state{object} Redux state.
 * @param action{object} Action object.
 *
 * @returns {{data: null, loading: boolean, error: (*|string)}|{data: null, loading: boolean, error: null}|{data, loading: boolean, error: null}}
 */
const getCampaigns = (state, action) => setReducer(state, action, "GET_CAMPAIGN");

export default getCampaigns;
