import {setReducerLite} from "../utils/reducers";

/**
 * Get job functions reducer.
 *
 * @param state{object} Redux state.
 * @param action{object} Action object.
 *
 * @returns {{items}|{}|{items: *[]}}
 */
const jobFunctions = (state = {}, action) => setReducerLite(state, action, "JOB_FUNCTIONS");

export default jobFunctions;
