import {setReducerLite} from "../utils/reducers";

/**
 * Get employee size reducer.
 *
 * @param state{object} Redux state.
 * @param action{object} Action object.
 *
 * @returns {{items}|{}|{items: *[]}}
 */
const employeeSize = (state = {}, action) => setReducerLite(state, action, "EMPLOYEE_SIZE");

export default employeeSize;
