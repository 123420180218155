import {Component} from "react";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";


/**
 * Scroll to top component.
 */
class ScrollToTop extends Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        return this.props.children;
    }
}

export default withRouter(ScrollToTop);

ScrollToTop.propTypes = {
    "location": PropTypes.object,
    "children": PropTypes.any.isRequired
};