import Cookies from "universal-cookie";
import {defaultDomain} from "./requests";

// noinspection JSValidateTypes
const cookies = new Cookies();

/**
 * Get bearer from cookie.
 * Checking by "leadology_bearer" name.
 *
 * @returns {string|boolean} Bearer or false if not exists.
 */
export const getBearer = () => {
    let bearer = cookies.get("leadology_bearer");
    return bearer && bearer !== "false" ? bearer : false;
}

/**
 * Set bearer into cookie.
 * Cookie name will be "leadology_bearer".
 *
 * @param bearer{string} Bearer token.
 */
export const setBearer = (bearer) => {
    if (!bearer) {
        return;
    }
    cookies.set("leadology_bearer", bearer, {domain: defaultDomain(), path: "/"});
}

/**
 * Unset/Remove bearer from cookie.
 *
 */
export const unsetBearer = () => {
    cookies.remove("leadology_bearer", {domain: defaultDomain(), path: "/"});
    localStorage.clear();
}