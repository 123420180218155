import React from "react";

import thunk from "redux-thunk";
import {Provider} from "react-redux";
import {Router} from "react-router-dom";
import {applyMiddleware, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";

import Layout from "app/layout";
import Reducers from "app/reducers";
import history from "app/utils/history";
import ScrollToTop from "app/utils/scrollToTop";

const middleware = [thunk];
const store = createStore(
    Reducers,
    composeWithDevTools(applyMiddleware(...middleware))
);

const App = () => {
    return (
        <Provider store={store}>
            <Router history={history}>
                <ScrollToTop>
                    <Layout/>
                </ScrollToTop>
            </Router>
        </Provider>
    );
};

export default App;
