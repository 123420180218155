/**
 * Reducer initial state values.
 *
 * @type {{date: {forBody: string, forShow: string, originalDate: string}}}
 */
const initialState = {
    date: {forShow: "", forBody: "", originalDate: ""},
};

/**
 * Dates reducer.
 *
 * @param state{object} Redux state.
 * @param action{object} Action object.
 *
 * @returns {{date: {forBody: string, forShow: string, originalDate:string}}|{date}}
 */
const helperData = (state = initialState, action) => {
    switch (action.type) {
        case "SET_DATE":
            return {
                ...state,
                date: action.payload,
            };
        default:
            return state;
    }
};

export default helperData;
