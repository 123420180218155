import {setReducerLite} from "../utils/reducers";

/**
 * Get Industries reducer.
 *
 * @param state{object} Redux state.
 * @param action{object} Action object.
 *
 * @returns {{items}|{}|{items: *[]}}
 */
const industries = (state = {}, action) => setReducerLite(state, action, "INDUSTRIES");

export default industries;
