import {setReducerLite} from "../utils/reducers";

/**
 * NAICS reducer.
 *
 * @param state{object} Redux state.
 * @param action{object} Action object.
 *
 * @returns {{items}|{}|{items: *[]}}
 */
const naics = (state = {}, action) => setReducerLite(state, action, "NAICSES");

export default naics;
