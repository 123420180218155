// noinspection NpmUsedModulesInstalled,JSUnusedGlobalSymbols,JSValidateJSDoc

import { sendReject, sendResolve } from "./helpers";
import endpointMapping from "./enpoints";
import axios from "axios";
import { getBearer } from "./cookies";

/**
 * show/hide play button.
 *
 * @returns {boolean}
 */

export const showPlay = process.env.REACT_APP_PLAY_SHOW;
/**
 * Get default domain.
 *
 * @returns {string}
 */
export const defaultDomain = () => process.env.REACT_APP_COOKIE_DOMAIN;

/**
 * Get Base URL from env.
 *
 * @returns {string}
 */
export const getBaseUrl = () => process.env.REACT_APP_BASE_URL;

/**
 * Get WP application URL.
 *
 * @returns {string}
 */
export const getWpURL = () => process.env.REACT_APP_WP_URL;

/**
 * Get My account url to WP dashboard.
 * @returns {string}
 */
export const getWpAccountUrl = () => getWpURL() + "my-account/";

/**
 * Get API versioned url.
 *
 * @returns {string}
 */
export const getApiBaseURL = () => getBaseUrl() + "/api/v1/";

/**
 * AXIOS instance.
 *
 * @type {AxiosInstance}
 */
export const axiosInstance = axios.create({
  getBaseUrl,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
    Accept: "*/*",
  },
  proxy: process.env.REACT_APP_PROXY,
});

/**
 * Axios success response handler.
 * @param response
 */
const successResponse = (response) => response;

/**
 * Axios error handler.
 *
 * @param error
 * @returns {Promise<never>}
 */
const errorResponse = (error) => Promise.reject(error);

axiosInstance.interceptors.response.use(successResponse, errorResponse);

/**
 * Main GET method.
 *
 * @param id{
 * "login", "loginReset", "loginForgot" |
 * "personalData" |
 * "naics", "revenues", "jobLevels", "industries", "countries", "jobFunctions", |
 * "employeeSize", "searchContact", "addCampaign", "userActivePackage", |
 * "userCampaign", "userCampaign", "callStatistics", "campaignByID", "conversationModel" |
 * "campaignPaused", "campaignCompleted", "campaignStarted"
 * }
 * @param data{?object}
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
export const GET = (id, data = {}) => {
  let url = getUrl(id);
  if (Object.keys(data).length) {
    url += "?" + new URLSearchParams(data).toString();
  }
  return axiosInstance.get(url, {
    headers: { Authorization: `Bearer ${getBearer()}` },
  });
};

/**
 * Main POST method.
 *
 * @param id{
 * "login", "loginReset", "loginForgot" |
 * "personalData" |
 * "naics", "revenues", "jobLevels", "industries", "countries", "jobFunctions", |
 * "employeeSize", "searchContact", "addCampaign", "userActivePackage", |
 * "userCampaign", "userCampaign", "callStatistics", "campaignByID", "conversationModel" |
 * "campaignPaused", "campaignCompleted", "campaignStarted"
 * }
 * @param data{?object|?string}
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
export const POST = (id, data) => {
  if (!getBearer()) {
    return Promise.reject("Unable to get bearer.");
  }
  return axiosInstance.post(
    getUrl(id),
    typeof data === "string" ? data : JSON.stringify(data),
    {
      headers: {
        Authorization: `Bearer ${getBearer()}`,
      },
    }
  );
};

/**
 * Get URL for corresponding endpoint.
 *
 * @param id{
 * "login", "loginReset", "loginForgot" |
 * "personalData" |
 * "naics", "revenues", "jobLevels", "industries", "countries", "jobFunctions", |
 * "employeeSize", "searchContact", "addCampaign", "userActivePackage", |
 * "userCampaign", "userCampaign", "callStatistics", "campaignByID", "conversationModel" |
 * "campaignPaused", "campaignCompleted", "campaignStarted"
 * }
 * @returns {string}Specified URL.
 */
export const getUrl = (id) => {
  const apiBasedUrl = getApiBaseURL();
  return Object.prototype.hasOwnProperty.call(endpointMapping, id)
    ? apiBasedUrl + endpointMapping[id]
    : apiBasedUrl;
};

/**
 * Dispatcher action processor for GET method.
 *
 * @param actionId{
 * "login", "loginReset", "loginForgot" |
 * "personalData" |
 * "naics", "revenues", "jobLevels", "industries", "countries", "jobFunctions", |
 * "employeeSize", "searchContact", "addCampaign", "userActivePackage", |
 * "userCampaign", "userCampaign", "callStatistics", "campaignByID", "conversationModel" |
 * "campaignPaused", "campaignCompleted", "campaignStarted"
 * }
 * @param dispatch Dispatcher.
 * @param dispatchType Dispatch Type.
 * @param args Arguments. Optional.
 *
 * @returns {Promise<void>}
 */
export const processGetAction = (
  actionId,
  dispatch: any,
  dispatchType: string,
  args?: Object
) => {
  dispatch({ type: `${dispatchType}_START` });
  return GET(actionId, args)
    .then((res) => sendResolve(res, dispatch, `${dispatchType}_SUCCESS`))
    .catch((err) =>
      sendReject(
        err,
        `Cannot process action: ${dispatchType.toLowerCase()}.`,
        dispatch,
        `${dispatchType}_FAIL`
      )
    );
};

/**
 * Dispatcher action processor for POST method.
 *
 * @param actionId{
 * "login", "loginReset", "loginForgot" |
 * "personalData" |
 * "naics", "revenues", "jobLevels", "industries", "countries", "jobFunctions", |
 * "employeeSize", "searchContact", "addCampaign", "userActivePackage", |
 * "userCampaign", "userCampaign", "callStatistics", "campaignByID", "conversationModel" |
 * "campaignPaused", "campaignCompleted", "campaignStarted"
 * }
 * @param dispatch Dispatcher.
 * @param dispatchType Dispatch Type.
 * @param args Arguments. Optional.
 *
 * @returns {Promise<void>}
 */
export const processPostAction = (
  actionId,
  dispatch: any,
  dispatchType: string,
  args?: Object
) => {
  dispatch({ type: `${dispatchType}_START` });
  return POST(actionId, args)
    .then((res) => sendResolve(res, dispatch, `${dispatchType}_SUCCESS`))
    .catch((err) =>
      sendReject(
        err,
        `Cannot process action: ${dispatchType.toLowerCase()}.`,
        dispatch,
        `${dispatchType}_FAIL`
      )
    );
};
