import {combineReducers} from "redux";
import req from "./req";
import user from "./user";
import naics from "./naics";
import login from "./login";
import revenues from "./revenues";
import countries from "./countries";
import jobLevels from "./jobLevels";
import industries from "./industries";
import employeeSize from "./employeeSize";
import jobFunctions from "./jobFunctions";
import searchCriteria from "./searchCriteria";
import searchForContacts from "./searchForContacts";
import getActiveUserPackage from "./getActiveUserPackage";
import addCampaign from "./addCampaign";
import helperData from "./helperData";
import getCampaigns from "./getCampaigns";
import getCampaignById from "./getCampaignById";
import getConversationalModels from "./getConversationalModels";
import getCampaignStatistics from "./getCampaignStatistics";
import setCampaignStatusPaused from "./setCampaignStatus/setCampaignStatusPaused";
import setCampaignStatusCompleted from "./setCampaignStatus/setCampaignStatusCompleted";
import setCampaignStatusStarted from "./setCampaignStatus/setCampaignStatusStarted";

/**
 * Combination for all reducers.
 */
export default combineReducers({
    req,
    user,
    naics,
    login,
    revenues,
    countries,
    jobLevels,
    industries,
    employeeSize,
    jobFunctions,
    addCampaign,
    helperData,
    getCampaigns,
    getCampaignById,
    searchCriteria,
    searchForContacts,
    getActiveUserPackage,
    getConversationalModels,
    getCampaignStatistics,
    setCampaignStatusPaused,
    setCampaignStatusCompleted,
    setCampaignStatusStarted,
});
