import {unsetBearer} from "../utils/cookies";
import {clearLocalStorage} from "../utils/helpers";

/**
 * Logout request handler.
 *
 * @returns {(function(*): void)|*}
 */
const logoutReq = () => (dispatch) => {
    dispatch({
        type: "LOG_OUT",
    });
    dispatch({
        type: "CLEAN_USER",
    });
    unsetBearer()
    clearLocalStorage()
};

export default logoutReq;