import {setReducer} from "../utils/reducers";


/**
 * Get user active packages reducer.
 *
 * @param state{object} Redux state.
 * @param action{object} Action object.
 *
 * @returns {{data, err: (*|string), loading: boolean, error}}
 */
const getActiveUserPackage = (state, action) => setReducer(state, action, "GET_ACTIVE_USER");

export default getActiveUserPackage;
