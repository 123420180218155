/**
 * Reducer initial state values.
 * @type {{isReqProcess: boolean}}
 */
const initialState = {isReqProcess: false};

/**
 * Main request reducer.
 * Will check api requests start/full filled statuses.
 *
 * @param state{object} Redux state.
 * @param action{object} Action object.
 *
 * @returns {{isReqProcess: boolean}}
 */
const req = (state = initialState, action) => {
    switch (action.type) {
        case "START_REQ":
            return {
                ...state,
                isReqProcess: true,
            };
        case "END_REQ":
            return {
                ...state,
                isReqProcess: false,
            };
        default:
            return state;
    }
};

export default req;
