import {getLocalStorage} from "../utils/helpers";
import {setReducerLite} from "../utils/reducers";

/**
 * Reducer initial state values.
 *
 * @type {{items: (Object|null)}}
 */
const initialState = {
    items: getLocalStorage("search-criteria")
};

/**
 * Search criteria reducer.
 *
 * @param state{object} Redux state.
 * @param action{object} Action object.
 *
 * @returns {{items}|{items: null}|{items: Object|null}}
 */
const searchCriteria = (state = initialState, action) => setReducerLite(state, action, "SEARCH_CRITERIA");

export default searchCriteria;
