import {getBearer, unsetBearer} from "../utils/cookies";

/**
 * Reducer initial state values.
 *
 * @type {{isLoggedIn: (string|boolean), error: null, loading: boolean}}
 */
const initialState = {
    isLoggedIn: getBearer(),
    error: null,
    loading: false,
};

/**
 *
 * Login reducer.
 *
 * @param state{object} Redux state.
 * @param action{object} Action object.
 *
 * @returns {{isLoggedIn: boolean, error: (*|string), loading: boolean}|{isLoggedIn: boolean, error: null, loading: boolean}|{isLoggedIn: (string|boolean), error: null, loading: boolean}}
 */
const login = (state = initialState, action) => {
    switch (action.type) {
        case "LOGIN_START":
            return {
                ...state,
                isLoggedIn: false,
                loading: true,
                error: null,
            };
        case "LOGIN_SUCCESS":
            return {
                ...state,
                isLoggedIn: true,
                loading: false,
                error: null,
            };
        case "LOGIN_FAIL":
            unsetBearer();
            return {
                ...state,
                isLoggedIn: false,
                loading: false,
                error: action.payload?.data || action.payload || "Something went wrong",
            };
        case "LOG_OUT":
            return {
                ...state,
                isLoggedIn: false,
            };
        default:
            return state;
    }
};

export default login;
