import {getLocalStorage} from "../utils/helpers";
import {setReducerLite} from "../utils/reducers";

/**
 * Reducer initial state values.
 *
 * @type {{items: (Object|null)}}
 */
const initialState = {items: getLocalStorage("search-result")};

/**
 * Search for contacts reducer.
 *
 * @param state{object} Redux state.
 * @param action{object} Action object.
 *
 * @returns {{items}|{items: (Object|null)}|{items: null}}
 */
const searchForContacts = (state = initialState, action) => setReducerLite(state, action, "SEARCH_RESULT");

export default searchForContacts;
